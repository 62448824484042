<template>
    <marketing-layout>
        <marketing-hero class="mb-10">
            <h1 class="fw-bold heading-3 text-responsive mb-3">
                {{ $t('pages.marketing.contact.title') }}
            </h1>
            <h2 class="fw-light heading-5 text-responsive mb-0">
                {{ $t('pages.marketing.contact.subTitle') }}
            </h2>
            <a
                @click="showWebWidget"
                class="btn btn-primary mt-5"
            >
                {{ $t('pages.marketing.contact.message') }}
            </a>
        </marketing-hero>
    </marketing-layout>
</template>

<script>
    import Marketing from '../../layouts/Marketing'
    import MarketingHero from '@/components/MarketingHero'
    import zendeskMixin from '@/mixins/zendeskMixin'

    export default {
        name: 'Contact',
        metaInfo: {
            title: 'Get In Touch with Us',
            meta: [{ name: 'description', vmid: 'description', content: `The easiest way to get in touch with us is to send us an email.` }],
        },
        mixins: [zendeskMixin],
        mounted: function () {
            this.$logEvent('view_contact')
        },
        components: {
            'marketing-layout': Marketing,
            'marketing-hero': MarketingHero,
        },
    }
</script>
